import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";

import {
  Alert,
  Button,
  Card,
  Col,
  Row,
  Form,
  InputGroup,
} from "react-bootstrap";
//import "./Login.css";
import { auth, database } from "../firebase";
import NavBarAcct from "./NavBarAcct";
import { Checkbox, Message } from "semantic-ui-react";
import waveFooter from "../images/waveDarkBlueFooter.svg";
import Footer from "./Footer";
import { useAcctInfo } from "../context/AcctInfoContext";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailSignUp, setEmailSignUp] = useState("");
  const [agree, setAgree] = useState(false);
  const [strongPassword, setStrongPassword] = useState(false);

  const [passwordSignUp, setPasswordSignUp] = useState("");

  const passwordRef = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState();

  //const { showForgotPasswordPage, setShowForgotPasswordPage } = useDataSet();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const history = useNavigate();
  const { resetPassword, signup, login, errorMsg } = useAcctInfo();

  useEffect(() => {
    console.log({ errorMsg });
    switch (errorMsg) {
      case "auth/email-already-in-use":
        return setError("Account already exist. Please login instead.");

      case "auth/invalid-email":
        return setError("Email provided is invalid");

      case "auth/unverified-email":
        return setError("The operation requires a verified email.");

      case "auth/wrong-password":
        return setError("The password is incorrect. Please try again.");

      case "auth/invalid-email":
        return setError("The email address is badly formatted.");

      case "auth/user-not-found":
        return setError(
          "User account not found. Please sign up to create a user account."
        );

      case "auth/user-disabled":
        return setError(
          "The user account has been disabled. Please contact C.Cots at support@ccots.app."
        );

      case "auth/network-request-failed":
        return setError(
          "A network error (such as timeout, interrupted connection or unreachable host) has occurred."
        );

      case "auth/no-auth-event":
        return setError(
          "An internal error has occurred. Please try again at a later time."
        );

      // Many more authCode mapping here...

      default:
        return "";
    }

    return () => {};
  }, [errorMsg]);

  function handleReset(e) {
    e.preventDefault();

    try {
      setMsg(
        "Successful. Please check your inbox or spam folder to complete your password reset."
      );
      setLoading(true);
      resetPassword(email);

      // setAcctExist(true);
      // history("/account", { replace: true });
    } catch {
      setError("Failed to login");
    }
    setLoading(false);
  }
  return (
    <div>
      <NavBarAcct />
      <div style={{ margin: "25%" }}>
        {/* {error && { error }}*/}
        <Card>
          <Card.Header>Reset Password</Card.Header>
          {errorMsg && (
            <Alert variant="danger" style={{ margin: "5px" }}>
              {error}
            </Alert>
          )}

          {!errorMsg && msg ? (
            <Alert variant="success" style={{ margin: "5px" }}>
              {msg}
            </Alert>
          ) : (
            ""
          )}

          <div>
            <div style={{ padding: "50px" }}>
              <Card.Body>
                {" "}
                {/* {formErrors.zipCode && <span>{formErrors.zipCode}</span>} */}
                <Form.Group>
                  <Form.Control
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <span>
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", marginBottom: "5px" }}
                  >
                    Sign Up or Sign In?
                  </Link>
                </span>
              </Card.Body>{" "}
              <Button
                style={{ marginTop: "10px" }}
                onClick={handleReset}
                size="large"
                disabled={loading || !email}
              >
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </div>
      <section
        className="section"
        style={{
          background: `url(${waveFooter}) bottom no-repeat `,
          padding: "250px",
        }}
      ></section>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
