import React from "react";
import Contact from "./Contact";
import Footer from "./Footer";
import Navbar from "./Navbar";
import wave from "../images/wave_lyghtAI.svg";
import KeyFeature from "./KeyFeature";
import Features from "./Features";
import Section from "./Section";
import "../Apps.scss";
import "../assets/css/materialdesignicons.min.css";
import "../assets/css/default.css";
import waveFooter from "../images/waveDarkBlueFooter.svg";
import bloomberg from "../images/Bloomberg-Logo-2004.jpeg";
import deloitte from "../images/deloitte_logo.png";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
} from "reactstrap";

function LandingPage() {
  return (
    <div>
      {" "}
      <Navbar />
      <section id="home">
        <Section />
      </section>
      <section
        className="section"
        id="services"
        style={{ background: `url(${wave})  no-repeat` }}
      >
        <section className="section">
          <Features />
        </section>
        <section style={{ background: "#f8f9fc", padding: "25px " }}>
          <KeyFeature />
        </section>
      </section>
      <section
        className="section"
        id="contact"
        style={{
          background: `url(${waveFooter}) bottom no-repeat `,
          padding: "0 50px 450px 50px",
        }}
      >
        <Contact />
      </section>
      <Footer />
    </div>
  );
}

export default LandingPage;
