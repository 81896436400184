import React, { Component } from "react";
import { a, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import waveFooter from "../images/wave_footer.svg";

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import americanEx from "../images/american-ex.png";
import discover from "../images/discover.png";
import masterCard from "../images/master-card.png";
import paypal from "../images/paypal.png";
import visa from "../images/visa.png";
// import { FiChevronLeft } from "react-icons/fi";

//Import Images
import logolight from "../images/logo-light.png";
import logodark from "../images/logo-dark.png";
import { Divider } from "semantic-ui-react";

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <span
        //style={{ background: `url(${waveFooter}) top no-repeat ` }}
        >
          <footer
            style={{
              backgroundColor: "#283353",
              //style={{ background: `url(${waveFooter}) top no-repeat ` }}
            }}
          >
            <Container>
              <Row>
                <Col
                  sm="4"
                  xs="6"
                  className="mb-0 mb-md-4 pb-0 pb-md-2"
                  name="footercolumn"
                >
                  <h5 className="text-light footer-head">Contact</h5>
                  <ListGroup>
                    <ListGroupItem style={{ background: "#283353" }}>
                      <span>
                        <label>
                          <b className="text-muted">Info</b>
                        </label>
                        <p className="text-muted">info@ccots.app</p>
                      </span>
                    </ListGroupItem>

                    <ListGroupItem style={{ background: "#283353" }}>
                      <span>
                        <label>
                          <b className="text-muted">Support</b>
                        </label>
                        <p className="text-muted">support@ccots.app</p>
                      </span>
                    </ListGroupItem>
                    <ListGroupItem style={{ background: "#283353" }}>
                      <span>
                        <label>
                          <b className="text-muted">Careers</b>
                        </label>
                        <p className="text-muted">jobs@ccots.app</p>
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                </Col>
                {/* <Col
                  sm="4"
                  xs="6"
                  className="mb-0 mb-md-4 pb-0 pb-md-2"
                  name="footercolumn"
                >
                 
                  <h5 className="text-light footer-head">Corporations</h5>
                  <p className="mt-4 text-muted">
                    C.Cots App, LLC is a for-profit enterprise solutions software
                    primarily for midsize to large scale organizations. To start
                    working with C.Cots App software please submit a request via
                    the contact form provided on the website. A representative
                    will reach out to you shortly after.
                  </p>
                </Col> */}

                <Col
                  sm="4"
                  xs="6"
                  className="mt-4 mt-sm-0 pt-2 pt-sm-0"
                  name="footercolumn"
                >
                  <h5 className="text-light footer-head">Government</h5>
                  <p className="mt-4 text-muted">
                    C.COTS App, LLC also provides services and products to
                    government agencies in the U.S. and in some cases smaller
                    organizations. C.COTS App is a U.S based company. For more
                    details please submit a request via the contact form
                    provided on the website with "Government" selected as the
                    subject. A representative will reach out to you shortly
                    after.
                  </p>{" "}
                </Col>

                <Col
                  sm="4"
                  xs="6"
                  className="mt-4 mt-sm-0 pt-2 pt-sm-0"
                  name="footercolumn"
                >
                  <h5 className="text-light footer-head">NAICS Coverage</h5>
                  <ul
                    className="mt-4 text-muted"
                    style={{ listStyleType: "none" }}
                  >
                    <li>
                      {" "}
                      541715- Computer and related hardware research and
                      development laboratories or services (except
                      nanotechnology research and development)
                    </li>
                    <li>541519-Other Computer Related Services </li>
                    <li>541618-Other Management Consulting Services</li>
                    <li>
                      541990-All Other Professional, Scientific & Technical
                      Services
                    </li>{" "}
                    <li>561320-Temporary Help Services</li>
                    <li>561499-All Other Business Support Services</li>
                    <li>
                      {" "}
                      541511- Applications software programming services, custom
                      computer/ Computer software support services, custom
                      518210- Data processing computer services
                    </li>
                  </ul>{" "}
                </Col>
              </Row>
            </Container>
          </footer>
          <footer
            className="footer footer-bar"
            style={{ backgroundColor: "#283353", borderTopColor: "black" }}
          >
            <Container className="text-center">
              <Row className="align-items-center">
                <Col sm="6">
                  <div className="text-sm-left">
                    <p className="mb-0 text-muted">
                      © 2023 C.Cots App. All rights reserved.{" "}
                    </p>
                  </div>
                </Col>

                <Col sm="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <ul className="list-unstyled text-sm-right mb-0">
                    <li className="list-inline-item mr-1">
                      {" "}
                      <Link to="/terms" style={{ textDecoration: "none" }}>
                        <span
                          className="text-muted small"
                          style={{ marginRight: "5px" }}
                        >
                          Terms of Use
                        </span>
                      </Link>
                      <span className="text-muted">|</span>
                      <Link to="/privacy" style={{ textDecoration: "none" }}>
                        <span
                          className="text-muted small"
                          style={{ marginRight: "15px", marginLeft: "5px" }}
                        >
                          Privacy Policy
                        </span>
                      </Link>
                      <a to="">
                        <img
                          src={americanEx}
                          className="avatar avatar-ex-sm"
                          title="American Express"
                          alt=""
                        />
                      </a>
                    </li>
                    <li className="list-inline-item mr-1">
                      <a to="">
                        <img
                          src={discover}
                          className="avatar avatar-ex-sm"
                          title="Discover"
                          alt=""
                        />
                      </a>
                    </li>
                    <li className="list-inline-item mr-1">
                      <a to="">
                        <img
                          src={masterCard}
                          className="avatar avatar-ex-sm"
                          title="Master Card"
                          alt=""
                        />
                      </a>
                    </li>
                    <li className="list-inline-item mr-1">
                      <a to="">
                        <img
                          src={paypal}
                          className="avatar avatar-ex-sm"
                          title="Paypal"
                          alt=""
                        />
                      </a>
                    </li>
                    <li className="list-inline-item mr-1">
                      <a to="">
                        <img
                          src={visa}
                          className="avatar avatar-ex-sm"
                          title="Visa"
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </footer>
        </span>
      </React.Fragment>
    );
  }
}

export default Footer;
