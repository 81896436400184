import React, { useState, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Divider, Icon, Input } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHamburger } from "@fortawesome/free-solid-svg-icons";
import logo from "../images/ccots app logos/ccots word logo/ccots Inverted Color Transparent bg.svg";

import "./Navbar.css";
import { Button } from "react-bootstrap";
import { auth } from "../firebase";

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <>
      <nav>
        {(toggleMenu || screenWidth > 850) && (
          <div>
            <ul className="list">
              <li
                className="items"
                style={{
                  border: "none",
                  borderRadius: "0px",
                }}
              >
                <span style={{ fontWeight: "800", fontSize: "18px" }}>
                  C.COTS
                </span>
              </li>

              {/* <a href="#home" style={{ textDecoration: "none" }}>
                <li className="items" style={{ textTransform: "uppercase" }}>
                  <img src={logo} width="100" height="150" />
                </li>
              </a> */}
              <a href="#home" style={{ textDecoration: "none" }}>
                <li className="items" style={{ textTransform: "uppercase" }}>
                  Home
                </li>
              </a>
              <a href="#services" style={{ textDecoration: "none" }}>
                <li className="items" style={{ textTransform: "uppercase" }}>
                  Services
                </li>
              </a>
              <a href="#contact" style={{ textDecoration: "none" }}>
                <li className="items" style={{ textTransform: "uppercase" }}>
                  Contact
                </li>
              </a>
              <div
                style={{
                  marginLeft: "50%",
                  marginTop: "10px",
                  marginBottom: "0px",
                }}
              >
                <Link
                  to={auth.currentUser ? "/account" : "/login"}
                  style={{ textDecoration: "none" }}
                >
                  <li className="account">Sign Up/Sign In</li>
                </Link>
              </div>
            </ul>
          </div>
        )}

        <button onClick={toggleNav} className="btnNav">
          {" "}
          <FontAwesomeIcon icon={faBars} />
        </button>
      </nav>
    </>
  );
}
