import React, { Component } from "react";
import { a } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

// import backGround_images
import build from "../images/build.png";

//Import Icons
import FeatherIcon from "feather-icons-react";

import CountDown from "count-down-react";
import ContactHero from "./ContactHero";

const date1 = Date.now() + 1006 * 60 * 60 * 1000;
const CoundownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <div className="container">
      <div>
        <h2>
          {" "}
          <b style={{ color: "maroon", fontFamily: "sans-serif" }}>
            The new Windows version will be available on 28 Feb 2022
          </b>
        </h2>
      </div>
      {/* <Container>
        <div style={{ display: "flex" }}>
          <div style={{ display: "inline", background: "lightgrey" }}>
            <div>Days</div>
            <div style={{ fontSize: "100px", padding: "10px", color: "red" }}>
              {days}
            </div>
          </div>
          <div style={{ display: "inline", background: "lightgrey" }}>
            <div>Hours</div>
            <div style={{ fontSize: "100px", padding: "10px", color: "red" }}>
              {hours}
            </div>
          </div>
          <div style={{ display: "inline", background: "lightgrey" }}>
            <div>Minutes</div>
            <div style={{ fontSize: "100px", padding: "10px", color: "red" }}>
              {minutes}
            </div>
          </div>
          <div style={{ display: "inline", background: "lightgrey" }}>
            <div>Seconds</div>
            <div style={{ fontSize: "100px", padding: "10px", color: "red" }}>
              {seconds}
            </div>
          </div>
        </div>
      </Container> */}
    </div>
  );
};

export default class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <span
          className="bg-half-170 bg-light d-table w-100"
          style={{ background: `url(${build}) bottom no-repeat` }}
        >
          <Container>
            {" "}
            {/* <CountDown date={date1} renderer={CoundownRenderer} /> */}
            <Row className="align-items-center mt-5">
              <Col lg={7} md={6}>
                <div className="title-heading">
                  <h4 className="heading my-3">
                    Quick. <br />
                    Simple. Time and Cost Savings.
                  </h4>
                  <p className="para-desc text-muted">
                    Finally, an AI assisted software that combines the power of
                    table extraction, data transformation, and machine learning
                    model creation in just a few clicks.
                  </p>

                  <div className="mt-4 pt-2">
                    <a href="#services" className="btn btn-primary m-1">
                      Explore Now
                    </a>
                  </div>
                </div>
              </Col>

              <Col lg={5} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <Card className="shadow rounded border-0 ml-lg-4">
                  <ContactHero />
                </Card>
              </Col>
            </Row>
          </Container>
        </span>
      </React.Fragment>
    );
  }
}
