import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon, Input } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHamburger } from "@fortawesome/free-solid-svg-icons";

import "./Navbar.css";
import { auth } from "../firebase";

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <nav>
      {(toggleMenu || screenWidth > 80) && (
        <div>
          <ul className="list">
            <li
              className="items"
              style={{
                border: "none",
                borderRadius: "0px",
              }}
            >
              <span style={{ fontWeight: "800", fontSize: "18px" }}>
                C.COTS
              </span>
            </li>

            <a
              href="https://ccots.app/#home"
              style={{ textDecoration: "none" }}
            >
              <li className="items" style={{ textTransform: "uppercase" }}>
                Home
              </li>
            </a>
            <a
              href="https://ccots.app/#services"
              style={{ textDecoration: "none" }}
            >
              <li className="items" style={{ textTransform: "uppercase" }}>
                Services
              </li>
            </a>
            <a
              href="https://ccots.app/#contact"
              style={{ textDecoration: "none" }}
            >
              <li className="items" style={{ textTransform: "uppercase" }}>
                Contact
              </li>
            </a>
            {auth.currentUser ? (
              <div
                style={{
                  marginLeft: "55%",
                  marginTop: "10px",
                  marginBottom: "0px",
                }}
              >
                <Link
                  to="/login"
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    auth.signOut();
                  }}
                >
                  <li className="account">Sign Out</li>
                </Link>
              </div>
            ) : (
              <div
                style={{
                  marginLeft: "50px",
                  marginTop: "10px",
                  marginBottom: "0px",
                }}
              >
                <Link to="/login" style={{ textDecoration: "none" }}>
                  <li></li>
                </Link>
              </div>
            )}
          </ul>
        </div>
      )}

      <button onClick={toggleNav} className="btnNav">
        {" "}
        <FontAwesomeIcon icon={faBars} />
      </button>
    </nav>
  );
}
