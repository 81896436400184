import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import NavBarAcct from "./NavBarAcct";
import { auth, database } from "../firebase";
import "./CreateAccount.css";
import emailjs from "@emailjs/browser";
import Footer from "./Footer";
import waveFooter from "../images/waveDarkBlueFooter.svg";
import { useAcctInfo } from "../context/AcctInfoContext";

//import { Link, useNavigate } from "react-router-dom";

function Account() {
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState("Submit");
  const [data, setData] = useState("");
  const { currentUser } = useAcctInfo();

  const [acct, setAcct] = useState("");

  ////////

  const intialValues = {
    companyName: "",
    street: "",
    street2: "",
    state: "",
    zipCode: "",
    city: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
    phoneNumber2: "",
    ext: "",
    ext2: "",
    adminEmail: "",
    isPaid: false,
  };

  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const submit = () => {
    if (isSubmitting) {
      database.account
        .doc(acct)
        .collection("AcctInfo")
        .doc(acct)
        .set({
          companyName: formValues.companyName || null,

          name: `${formValues.firstName} ${formValues.lastName}` || null,
          accountId: acct || null,
          address:
            `${formValues.street} ${formValues.street2 || ""} 
            ${formValues.city} ${formValues.state} ${formValues.zipCode}` ||
            null,
          jobTitle: formValues.jobTitle || null,

          companyPhone: formValues.phoneNumber || null,
          ext: formValues.ext || null,

          companyPhone2: formValues.phoneNumber2 || null,
          ext2: formValues.ext2 || null,
          email: formValues.email,
          isPaid: false,
          createdAt: database.getCurrentTimestamp(),
          createdBy: auth.currentUser.email || null,
          // email: auth.currentUser.email,
        });
      console.log("Account Created", acct);
    }
  };

  //input change handler
  const handleChange = (e) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
    const ranNum = Math.floor(Math.random() * 1000);
    const acctId = `${randomLetter}${ranNum}-${formValues.companyName.replace(
      /\s+/g,
      ""
    )}`;
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setAcct(acctId);

    for (const [key, value] of Object.entries(formValues)) {
      let data = `${key}: ${value}`;
      setData(data);
    }

    setValues((values) => ({
      ...values,
      fullName: `${formValues.firstName} ${formValues.lastName}`,
      email: formValues.email,
      subject: `Account Created by ${formValues.firstName} from ${formValues.companyName} `,
      message: `Account ID: ${acctId}, Contact: ${formValues.phoneNumber}${
        formValues.ext ? `EXT: ${formValues.ext}` : ""
      }, Info: ${data} `,
    }));
  };

  //form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setStatus("Sending...");
    if (
      formValues.companyName !== "" ||
      formValues.firstName !== "" ||
      formValues.email !== ""
    ) {
      emailjs
        .send(
          "service_qsgd0sw",
          "template_cs1yd64",
          values,
          "user_q8xVqCu2kmAwGoAdSnwFb"
        )
        .then(
          (result) => {
            console.log(result.text);
            setStatus("Submit");
            // isSuccess(true);
          },
          (error) => {
            console.log("Emailjs::", error.text);
          }
        );
    }
    setIsSubmitting(true);
  };

  //form validation handler
  const validate = (formValues) => {
    let errors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexPhone =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i;
    const regexZipCode = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/i;

    if (!formValues.companyName) {
      errors.companyName = "Company name can not be blank";
    }
    if (!formValues.firstName) {
      errors.firstName = "First name can not be blank";
    }

    if (!formValues.lastName) {
      errors.firstName = "Last name can not be blank";
    }

    if (!formValues.street) {
      errors.street = "Street can not be blank";
    }

    if (!formValues.city) {
      errors.city = "City can not be blank";
    }

    if (!formValues.state) {
      errors.state = "State can not be blank";
    }

    if (!formValues.jobTitle) {
      errors.jobTitle = "Job Title can not be blank";
    }

    // if (!formValues.ext2) {
    //   errors.state = "Cannot be blank";
    // }

    if (!formValues.zipCode) {
      errors.zipCode = "Zip code can not be blank";
    } else if (!regexZipCode.test(formValues.zipCode)) {
      errors.zipCode = "Invalid zip code format";
    }

    if (!formValues.phoneNumber) {
      errors.phoneNumber = "Phone number can not be blank";
    } else if (!regexPhone.test(formValues.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number format";
    }

    // if (!formValues.phoneNumber2) {
    //   errors.phoneNumber2 = "Cannot be blank";
    // } else if (!regexPhone.test(formValues.phoneNumber2)) {
    //   errors.phoneNumber2 = "Invalid phone number format";
    // }

    if (!formValues.email) {
      errors.email = "Email can not be blank";
    } else if (!regexEmail.test(formValues.email)) {
      errors.email = "Invalid email format";
    }

    // if (!formValues.password) {
    //   errors.password = "Cannot be blank";
    // } else if (formValues.password.length < 4) {
    //   errors.password = "Password must be more than 4 characters";
    // }

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      // setValidated(true);
      console.log("Account Submitted!!");
      submit();
      //setFormValues(intialValues);
    }
  }, [isSubmitting]);

  return (
    <div>
      <NavBarAcct />

      <div style={{ margin: "150px" }}>
        <h1>Create New Account</h1>
        <Container>
          {!auth.currentUser ? (
            <Alert variant="danger">Please login to create account</Alert>
          ) : (
            ""
          )}

          <form onSubmit={handleSubmit} noValidate>
            <Card style={{ margin: "15px" }}>
              {Object.keys(formErrors).length === 0 && isSubmitting && (
                <Alert variant="success">
                  Form submitted successfully. A representative will reach out
                  to you shortly.
                </Alert>
              )}
              {formErrors.phoneNumber && (
                <Alert variant="danger">{formErrors.phoneNumber}</Alert>
              )}{" "}
              {formErrors.companyName && (
                <Alert variant="danger">{formErrors.companyName}</Alert>
              )}{" "}
              {formErrors.firstName && (
                <Alert variant="danger">{formErrors.firstName}</Alert>
              )}{" "}
              {formErrors.lastName && (
                <Alert variant="danger">{formErrors.lastName}</Alert>
              )}{" "}
              {formErrors.zipCode && (
                <Alert variant="danger">{formErrors.zipCode}</Alert>
              )}{" "}
              {formErrors.city && (
                <Alert variant="danger">{formErrors.city}</Alert>
              )}{" "}
              {formErrors.jobTitle && (
                <Alert variant="danger">{formErrors.jobTitle}</Alert>
              )}
              <Card.Header>Company</Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Company name</Form.Label>
                    <Form.Control
                      type="text"
                      name="companyName"
                      id="companyName"
                      value={formValues.companyName}
                      onChange={handleChange}
                      className={formErrors.companyName && "input-error"}
                    />
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>

            <Card style={{ margin: "15px" }}>
              <Card.Header>Legal Address</Card.Header>
              <Card.Body>
                {" "}
                {formErrors.zipCode && <span>{formErrors.zipCode}</span>}
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Street Address 1</Form.Label>
                    <Form.Control
                      type="text"
                      name="street"
                      id="street"
                      value={formValues.street}
                      onChange={handleChange}
                      className={formErrors.street && "input-error"}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Street Address 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="street2"
                      id="street2"
                      value={formValues.street2}
                      onChange={handleChange}
                      className={formErrors.street2 && "input-error"}
                    />
                  </Form.Group>
                </Row>{" "}
                <Row className="mb-3">
                  <Form.Group as={Col} md="5">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      id="city"
                      value={formValues.city}
                      onChange={handleChange}
                      className={formErrors.city && "input-error"}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      name="state"
                      id="state"
                      value={formValues.state}
                      onChange={handleChange}
                      className={formErrors.state && "input-error"}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      value={formValues.zipCode}
                      onChange={handleChange}
                      className={formErrors.zipCode && "input-error"}
                    />
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>

            <Card style={{ margin: "15px" }}>
              <Card.Header>Representative</Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={formValues.firstName}
                      onChange={handleChange}
                      className={formErrors.firstName && "input-error"}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={formValues.lastName}
                      onChange={handleChange}
                      className={formErrors.lastName && "input-error"}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="4">
                    <Form.Label>Email</Form.Label>

                    <Form.Control
                      type="text"
                      name="email"
                      id="email"
                      value={formValues.email}
                      onChange={handleChange}
                      className={formErrors.email && "input-error"}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Primary Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={formValues.phoneNumber}
                      onChange={handleChange}
                      className={formErrors.phoneNumber && "input-error"}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Ext</Form.Label>
                    <Form.Control
                      type="text"
                      name="ext"
                      id="ext"
                      value={formValues.ext}
                      onChange={handleChange}
                      className={formErrors.ext && "input-error"}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Group>
                      <Form.Label>Job Title</Form.Label>

                      <Form.Control
                        type="text"
                        name="jobTitle"
                        id="jobTitle"
                        value={formValues.jobTitle}
                        onChange={handleChange}
                        className={formErrors.jobTitle && "input-error"}
                      />
                    </Form.Group>{" "}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4">
                    <Form.Label>Secondary Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phoneNumber2"
                      id="phoneNumber2"
                      value={formValues.phoneNumber2}
                      onChange={handleChange}
                      className={formErrors.phoneNumber2 && "input-error"}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Ext</Form.Label>
                    <Form.Control
                      type="text"
                      name="ext2"
                      id="ext2"
                      value={formValues.ext2}
                      onChange={handleChange}
                      className={formErrors.ext2 && "input-error"}
                    />
                  </Form.Group>
                </Row>{" "}
                <Button disabled={!auth.currentUser} type="submit">
                  {status}
                </Button>
              </Card.Body>
            </Card>
          </form>
        </Container>
      </div>
      <section
        className="section"
        style={{
          background: `url(${waveFooter}) bottom no-repeat `,
          padding: "250px",
        }}
      ></section>
      <Footer />
    </div>
  );
}

export default Account;
