import React, { useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Message } from "semantic-ui-react";
import "./Contact.css";
import logo from "../images/ccots app logos/ccots  main logo/ccots Main Logo Transparent bg.svg";

import SectionTitleContact from "./SectionTitleContact";
import emailjs from "@emailjs/browser";

function ContactHero() {
  const [status, setStatus] = useState("Submit");
  const [success, isSuccess] = useState(false);

  const [values, setValues] = useState({
    fullName: "",
    email: "",
    subject: "more info",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    emailjs
      .send(
        "service_qsgd0sw",
        "template_a0ewy6i",
        values,
        "user_q8xVqCu2kmAwGoAdSnwFb"
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatus("Submit");
          isSuccess(true);
        },
        (error) => {
          console.log("Emailjs::", error.text);
        }
      );
  };

  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div>
      {/* <CardBody> */}
      {/* <h2 className="card-title text-center"> Get More Info</h2>
      <p className="card-title text-center">
        Include your name and email to get more info about C.Cots.
      </p> */}
      <img src={logo} width="400" height="350" />

      {/* <Form onSubmit={handleSubmit} className="login-form mt-4">
          <Row>
            {success == true ? (
              <Alert
                style={{
                  backgroundColor: "#00e600",
                  color: "black",
                  // borderRadius: "0px",
                }}
              >
                {" "}
                <h6>
                  Thank you for requesting more info. We will get back to you
                  shortly.{" "}
                </h6>
              </Alert>
            ) : (
              ""
            )}
            <Col md={12}>
              <FormGroup>
                <div className="position-relative">
                  <input
                    id="email"
                    type="text"
                    name="fullName"
                    className="form-control pl-5"
                    placeholder="Name"
                    value={values.fullName}
                    onChange={handleChange}
                    required=""
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <div className="position-relative">
                  <input
                    id="email"
                    type="text"
                    className="form-control pl-5"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    required=""
                  />
                </div>
              </FormGroup>
            </Col>

            <Col md={12}>
              <button className="btn btn-block btn-primary" type="submit">
                {status}
              </button>
            </Col>
          </Row>
        </Form> */}
      {/* </CardBody> */}
    </div>
  );
}

export default ContactHero;
