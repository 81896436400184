import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect,
  Navigate,
} from "react-router-dom";
import Account from "./Account";
import { auth, database } from "../firebase";
import { useAcctInfo } from "../context/AcctInfoContext";

import LandingPage from "./LandingPage";
import Login from "./Login";
import Privacy from "./Privacy";
import Terms from "./Terms";
import Download from "./Download";
import ForgotPassword from "./ForgotPassword";

function HomePage() {
  const { currentUser, signup, login } = useAcctInfo();

  return (
    <div>
      <Routes>
        {auth.currentUser ? (
          <>
            {" "}
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/account" element={<Account />} />
            <Route path="/login" element={<Navigate to="/account" />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/" element={<LandingPage />} />
            {/* <Route path="/" element={<Navigate to="/home" />} /> */}
          </>
        ) : (
          <>
            {" "}
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/account" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/download" element={<Download />} />
            <Route path="/terms" element={<Terms />} />
            <Route exact path="/" element={<LandingPage />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default HomePage;
