import React, { useRef, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import "./Contact.css";
import SectionTitleContact from "./SectionTitleContact";
import waveFooter from "../images/wave_footer.svg";
import emailjs from "@emailjs/browser";
let url = window.location.pathname;

function Contact() {
  const [status, setStatus] = useState("Submit");
  const [success, isSuccess] = useState(false);

  const form = useRef();
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });
  console.log({ url });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    emailjs
      .send(
        "service_qsgd0sw",
        "template_cs1yd64",
        values,
        "user_q8xVqCu2kmAwGoAdSnwFb"
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatus("Submit");
          isSuccess(true);
        },
        (error) => {
          console.log("Emailjs::", error.text);
        }
      );
  };

  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Container>
      <SectionTitleContact
        title="Get In Touch"
        desc="Any question? Feel free to contact us. "
      />{" "}
      <Card
        className="shadow rounded border-0 ml-lg-2"
        style={{ marginBottom: "150px" }}
      >
        <CardBody>
          <Alert
            style={{
              backgroundColor: "#2342a9",
              color: "white",
              borderRadius: "0px",
            }}
          >
            <h6>
              If you already have a product key and need further assistance.
              Please include your product key in your message below.
            </h6>{" "}
          </Alert>{" "}
          <span className="card-title text-center">
            <h5>
              For all other inquiries please complete the contact form and click
              submit. A representative will reach out to you shortly.
            </h5>{" "}
          </span>
          <Form ref={form} onSubmit={handleSubmit} className="login-form mt-4">
            {success == true ? (
              <Alert
                style={{
                  backgroundColor: "#00e600",
                  color: "black",
                  // borderRadius: "0px",
                }}
              >
                {" "}
                <h6>
                  Thank you for reaching out to us. We will get back to you
                  shortly.{" "}
                </h6>{" "}
              </Alert>
            ) : (
              ""
            )}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <div className="position-relative">
                    <label htmlFor="name"></label>
                    <input
                      id="fullName"
                      className="form-control pl-5"
                      placeholder="Full Name"
                      type="text"
                      name="fullName"
                      required
                      value={values.fullName}
                      onChange={handleChange}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <div className="position-relative">
                    <label htmlFor="email"></label>
                    <input
                      id="email"
                      className="form-control pl-5"
                      placeholder="Email"
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </FormGroup>
              </Col>
              {/* <Col md={12}>
                  <FormGroup>
                    
                    <div className="position-relative">
                                        <label htmlFor="email">Email:</label>

                      <input
                        id="subject"
                        type="text"
                        className="form-control pl-5"
                        placeholder="Subject"
                        name="Subject"
                        required=""
                      />
                    </div>
                  </FormGroup>
                </Col> */}
              <Col md={12}>
                <FormGroup>
                  <div className="position-relative">
                    <Input
                      type="text"
                      name="subject"
                      id="subject"
                      rows="5"
                      cols="20"
                      value={values.subject}
                      onChange={handleChange}
                      placeholder="Subject"
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <div className="position-relative">
                    <Input
                      type="textarea"
                      name="message"
                      id="message"
                      rows="5"
                      cols="20"
                      value={values.message}
                      onChange={handleChange}
                      placeholder="Write Message..."
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md={12}>
                <button className="btn btn-block btn-primary" type="submit">
                  {status}
                </button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}

export default Contact;
