import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Icon, Input } from "semantic-ui-react";

import "./Navbar.css";

export default function NavEmpty() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <nav>
      {(toggleMenu || screenWidth > 500) && (
        <ul className="list">
          <a href="https://ccots.app/" style={{ textDecoration: "none" }}>
            <li className="items" style={{ textTransform: "uppercase" }}>
              <span style={{ fontWeight: "800", fontSize: "18px" }}>
                C.COTS
              </span>
            </li>
          </a>
        </ul>
      )}

      <button onClick={toggleNav} className="btnNav">
        <Icon name="bars" />
      </button>
    </nav>
  );
}
