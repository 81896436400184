import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";

import {
  Alert,
  Button,
  Card,
  Col,
  Row,
  Form,
  InputGroup,
} from "react-bootstrap";
//import "./Login.css";
import { auth, database } from "../firebase";
import NavBarAcct from "./NavBarAcct";
import { Checkbox, Message } from "semantic-ui-react";
import waveFooter from "../images/waveDarkBlueFooter.svg";
import Footer from "./Footer";
import { useAcctInfo } from "../context/AcctInfoContext";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailSignUp, setEmailSignUp] = useState("");
  const [agree, setAgree] = useState(false);
  const [strongPassword, setStrongPassword] = useState(false);

  const [passwordSignUp, setPasswordSignUp] = useState("");

  const passwordRef = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState();

  //const { showForgotPasswordPage, setShowForgotPasswordPage } = useDataSet();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const { currentUser, signup, login, errorMsg } = useAcctInfo();

  function handleSignUp(event) {
    event.preventDefault();

    if (passwordSignUp !== passwordConfirm) {
      console.log("no match");

      return setError("Passwords do not match");
    }

    try {
      if (strongPassword) {
        setError("");
        setLoading(true);

        signup(emailSignUp, passwordSignUp);
        // setAcctExist(true);
        history("/account");
      }
    } catch (error) {
      console.error(error);
      setError("Login Failed");
    }
    setLoading(false);
  }

  useEffect(() => {
    console.log({ errorMsg });
    switch (errorMsg) {
      case "auth/email-already-in-use":
        return setError("Account already exist. Please login instead.");

      case "auth/invalid-email":
        return setError("Email provided is invalid");

      case "auth/unverified-email":
        return setError("The operation requires a verified email.");

      case "auth/wrong-password":
        return setError("The password is incorrect. Please try again.");

      case "auth/invalid-email":
        return setError("The email address is badly formatted.");

      case "auth/user-not-found":
        return setError(
          "User account not found. Please sign up to create a user account."
        );

      case "auth/user-disabled":
        return setError(
          "The user account has been disabled. Please contact C.Cots at support@ccots.app."
        );

      case "auth/network-request-failed":
        return setError(
          "A network error (such as timeout, interrupted connection or unreachable host) has occurred."
        );

      case "auth/no-auth-event":
        return setError(
          "An internal error has occurred. Please try again at a later time."
        );

      // Many more authCode mapping here...

      default:
        return "";
    }

    return () => {};
  }, [errorMsg]);

  function handleLogin(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      login(email, password);
      // setAcctExist(true);
      history("/account", { replace: true });
    } catch {
      setError("Failed to login");
    }
    setLoading(false);
  }
  return (
    <div>
      <NavBarAcct />
      <div style={{ marginLeft: "25%" }}>
        <Row>
          {/* {error && { error }}*/}
          <Card style={{ marginTop: "150px" }}>
            <Card.Header>Login/Sign Up</Card.Header>
            {errorMsg && (
              <Alert variant="danger" style={{ margin: "5px" }}>
                {error}
              </Alert>
            )}

            <div style={{ display: "flex" }}>
              <div style={{ padding: "50px" }}>
                <Card.Body>
                  {" "}
                  {/* {formErrors.zipCode && <span>{formErrors.zipCode}</span>} */}
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Control
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <span>
                    <Link
                      to="/forgotPassword"
                      style={{ textDecoration: "none" }}
                    >
                      Forgot password?
                    </Link>
                  </span>
                  <div>
                    <Button
                      style={{ marginTop: "10px" }}
                      onClick={handleLogin}
                      size="large"
                      disabled={loading || (!email && !password)}
                    >
                      Login
                    </Button>
                  </div>
                </Card.Body>{" "}
              </div>
              <div style={{ padding: "50px" }}>
                <Card>
                  <Card.Header>Sign up</Card.Header>
                  <Card.Body>
                    {" "}
                    <div>
                      <span style={{ padding: "10px" }}>
                        <Form.Group>
                          <Form.Control
                            placeholder="Email address"
                            value={emailSignUp}
                            onChange={(e) => setEmailSignUp(e.target.value)}
                          />
                        </Form.Group>
                      </span>
                      <span style={{ padding: "10px" }}>
                        <Form.Group>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={passwordSignUp}
                            onChange={(e) => setPasswordSignUp(e.target.value)}
                          />
                        </Form.Group>
                      </span>{" "}
                      <span style={{ padding: "10px" }}>
                        <Form.Group>
                          <Form.Control
                            type="password"
                            placeholder="Confirm password"
                            value={passwordConfirm ?? ""}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                          />
                        </Form.Group>
                      </span>
                      {passwordSignUp ? (
                        <Alert variant="info">
                          <PasswordChecklist
                            rules={[
                              "minLength",
                              "specialChar",
                              "number",
                              "capital",
                              "match",
                            ]}
                            minLength={8}
                            value={passwordSignUp}
                            valueAgain={passwordConfirm}
                            onChange={(isValid) => {
                              setStrongPassword(isValid);
                              console.log({ isValid });
                            }}
                          />
                        </Alert>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <Checkbox
                        onChange={() => setAgree(!agree)}
                        label={{
                          children: (
                            <span style={{ margin: "10px" }}>
                              I agree to{" "}
                              <Link to="/terms">terms and condtions</Link>.
                            </span>
                          ),
                        }}
                        // onChange={(e, data) => setChecked(data.checked)}
                        // checked={checked}
                      />
                    </div>
                    <Button
                      variant="info"
                      onClick={(event) => {
                        handleSignUp(event);
                      }}
                      size="large"
                      disabled={
                        loading ||
                        agree === false ||
                        strongPassword === false ||
                        !emailSignUp
                      }
                    >
                      Sign Up
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Card>
        </Row>
      </div>
      <section
        className="section"
        style={{
          background: `url(${waveFooter}) bottom no-repeat `,
          padding: "250px",
        }}
      ></section>
      <Footer />
    </div>
  );
}

export default Login;
