import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import NavBarAcct from "./NavBarAcct";
import { auth, database } from "../firebase";
import "./CreateAccount.css";
import emailjs from "@emailjs/browser";
import Footer from "./Footer";
import waveFooter from "../images/waveDarkBlueFooter.svg";
import { useAcctInfo } from "../context/AcctInfoContext";
import "./Download.css";
import { Icon } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from "@fortawesome/free-brands-svg-icons";

//import { Link, useNavigate } from "react-router-dom";

function Download() {
  const [url, getURL] = useState();
  const [isSubmitting, setIsSubmitting] = useState(true);

  const downloadApp = () => {
    console.log("download button clicked");
    console.log({ url });
    if (url !== "") {
      database.user.doc(auth.currentUser.uid).set({
        downloaded: database.downloads,
        createdAt: database.getCurrentTimestamp(),
        name: auth.currentUser.displayName,
        email: auth.currentUser.email,
        userID: auth.currentUser.uid,
        platform: "webpage",
      });

      //console.log("User Created", acct);
    }
  };

  useEffect(() => {
    if (!url) {
      database.storageRef
        .refFromURL(
          "gs://lyghtai-website.appspot.com/lyghtAI_exe/lyght-ai Setup 0.1.0_dev_tools.zip"
        )
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'
          console.log({ url });
          getURL(url);
          //saveAs(url, "setup");
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            const blob = xhr.response;
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch((error) => {
          console.log({ error });
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break;
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              break;
          }
        });
    }
    return () => {};
  }, []);

  //input change handler

  return (
    <div>
      <NavBarAcct />

      <div>
        <section
          style={{
            padding: "50px 0 150px 0",
            backgroundColor: "ghostwhite",
            marginTop: "15px",
          }}
        >
          <h1>Download Application for Windows </h1>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "55%",
              margin: "auto",
            }}
          >
            <a
              href={url}
              className="button"
              download="lyght_AI"
              //onClick={(e) => downloadApp()}
            >
              <span>
                <h4>C.Cots App 2022.1</h4>
                <FontAwesomeIcon icon={faWindows} size="lg" />{" "}
              </span>
              <h6>Download</h6>
            </a>

            <Button
              disabled
              type="submit"
              size="lg"
              variant="secondary"
              style={{ margin: "5px", borderRadius: "2px", padding: "10px" }}
            >
              <h6>Closed Beta </h6>
              C.Cots App 2022.2
              <h6>Only available for developers and tester.</h6>
            </Button>
          </div>
        </section>
        <section
          style={{
            padding: "50px 0 150px 0",
            textAlign: "left",
            margin: "10%",
          }}
        >
          <Card style={{ margin: "10px" }}>
            <Card.Header>Before you get started</Card.Header>
            <Card.Body>
              <Card.Title>
                Please install required dependencies before downloading C.Cots
                App.
              </Card.Title>
              <Card.Text>
                Install latest Python (stable version only):
                <a href="https://www.python.org/downloads/" target="_blank">
                  Python 3 installation{" "}
                </a>
              </Card.Text>
              <Card.Text>
                Install latest Java(stable version only):{" "}
                <a
                  href="https://www.java.com/download/ie_manual.jsp"
                  target="_blank"
                >
                  Java Installation
                </a>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ margin: "10px" }}>
            <Card.Header>Next, install required python modules</Card.Header>
            <Card.Body>
              <Card.Title>
                Copy and paste in powershell and hit enter.
              </Card.Title>
              <Card.Text>
                <em>pip install lyghtai</em>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ margin: "10px" }}>
            <Card.Header>Download C.Cots App</Card.Header>
            <Card.Body>
              <a
                href={url}
                className="button"
                download="lyght_AI"
                //onClick={(e) => downloadApp()}
                // disabled
              >
                <span>
                  <h4>C.Cots App 2022.1</h4>
                  <FontAwesomeIcon icon={faWindows} size="lg" />{" "}
                </span>
                <h6>Download</h6>
              </a>
            </Card.Body>
          </Card>
        </section>
      </div>
      <section></section>
      <section
        className="section"
        style={{
          background: `url(${waveFooter}) bottom no-repeat `,
          padding: "250px",
        }}
      ></section>
      <Footer />
    </div>
  );
}

export default Download;
