import React, { Component } from "react";
import { Row, Col } from "reactstrap";

class SectionTitleContact extends Component {
  render() {
    return (
      <React.Fragment>
        <Row className="justify-content-center">
          <Col xs="12" className={this.props.isLeft ? "" : "text-center"}>
            <div className="section-title mb-4 pb-2" name="maintitle">
              <h4 className="title mb-4" name="sectiontitle">
                {" "}
                {this.props.title}{" "}
              </h4>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SectionTitleContact;
