import React from "react";
import { Container } from "reactstrap";
import NavEmpty from "./NavEmpty";

export default function Terms() {
  return (
    <div>
      <NavEmpty />

      <Container>
        <div style={{ margin: "150px 50px 0 100px" }}>
          <h2 style={{ textAlign: "initial", fontFamily: "initial" }}>
            Terms Of Use
          </h2>
          <p style={{ textAlign: "initial", fontFamily: "initial" }}>
            These terms of use (“Terms”) govern your access to and use of all
            C.COTS App-branded publicly available softwares, including sites
            located on ccots.app , as well as C.COTS App Services, and C.COTS
            App Software and any other pages that link to these Terms
            (collectively, the “Sites”). These Terms expressly do not govern
            your access to or use of the C.COTS App Platform Services (known as
            C.COTS App and C.COTS, C.COTS App Services, each located at
            *.cloud.ccots.app, or the related software at help.ccots.app and
            platform support services, together the “Platform Services”), which
            are subject to the C.COTS App Terms of Service (or with respect to
            the C.COTS App Services, the C.COTS App Services Terms of Service)
            or other written agreement in place between C.COTS App, LLC.
            (“C.COTS”) and our subscribers (“Subscribers”) (any such agreement,
            a “Services Agreement”). PLEASE READ CAREFULLY THESE TERMS AND THE
            C.COTS App PRIVACY POLICY (“PRIVACY POLICY”) WHICH IS INCORPORATED
            BY REFERENCE INTO THESE TERMS. BY ACCESSING OR USING ANY OF THE
            SITES, YOU REPRESENT THAT YOU ARE AT LEAST 18 YEARS OLD, YOU
            ACKNOWLEDGE AND AGREE THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS,
            AND YOU AGREE TO BE LEGALLY BOUND BY ALL OF THESE TERMS. IF YOU DO
            NOT AGREE TO ALL OF THESE TERMS, DO NOT ACCESS OR USE THE SITES. WE
            SUGGEST YOU PRINT A COPY OF THESE TERMS FOR YOUR RECORDS. Throughout
            the Terms, “we,” “us,” “our” and “ours” refer to C.COTS App, and
            “you,” “your” or “yours” refer to you personally (i.e., the
            individual who reads and agrees to be bound by these Terms) and, if
            you access the Sites on behalf of a legal entity, to that entity. If
            you are using the Sites on behalf of any entity you represent and
            warrant that you are authorized to accept these Terms on such
            entity’s behalf and, by accepting these Terms, you are hereby
            binding such entity to the Terms. 1. ACCEPTANCE OF TERMS C.COTS App
            provides the Sites to you conditioned upon your accepting all of the
            Terms, without modification. Your use of the Sites constitutes your
            agreement with such Terms. We reserve the right to change, modify,
            add to, or remove portions of these Terms in our sole discretion at
            any time and we will, at our sole discretion, either post the
            modification on https://ccots.app/terms or provide you with email
            notice of the modification. You should check these Terms
            periodically for changes and you can determine when these Terms were
            last revised by referring to the “Last Updated” reference at the top
            of these Terms. Any modification shall be effective immediately upon
            the uploading of modified Terms. You indicate your agreement to
            comply with, and be bound by, any such modification by continuing to
            use or access the Sites after modified Terms are posted. If the
            modified Terms are not acceptable to you, your sole recourse is to
            discontinue your use of the Sites. If you have registered for and
            opened an account through the Sites (an “Account”), you are entirely
            responsible for maintaining the confidentiality of your Account
            information, including your password, and for any and all activity
            that occurs under your Account. You agree to notify C.COTS App
            immediately of any unauthorized use of your Account or password, or
            any other breach of security. However, you will remain responsible
            for losses incurred by C.COTS App or by any other party due to your
            knowingly or inadvertently permitting unauthorized use of your
            Account or your Account information. You may not use anyone else’s
            ID, password or account at any time unless we expressly pre-approve
            such use, or unless expressly permitted under a Services Agreement.
            C.COTS App cannot and will not be liable for any loss or damage
            arising from your failure to comply with these obligations.
            Registration for any account is void where the user lacks the
            requisite eligibility for registration or if such registration is
            otherwise prohibited. Software (“Software”) or the Platform Services
            may be made available to you through the Sites. Your rights to
            access and use the Platform Services, including any Software will be
            subject to your agreement to the applicable Services Agreement
            governing your use of the Platform Services and to any terms and
            conditions of any applicable third party software license agreement
            (“Software License”) identified in the Software or on the web page
            providing access to the Software. You may not use any Software
            unless you agree to be bound by all terms and conditions of any
            applicable Software License. If there is a conflict between any
            Services Agreement and any Software License, the conflicting term of
            the Software License shall control but only to the extent necessary
            to eliminate the conflict. 2. LICENSE GRANT AND PROPRIETARY RIGHTS
            Provided that you fully comply at all times with these Terms and any
            other policies or restrictions posted on or transmitted through the
            Sites, C.COTS App grants you a limited, non-exclusive,
            non-transferable, revocable license to access and use the Sites.
            Except as otherwise specifically noted in these Terms or on the
            Sites, the Software, Submissions (as later defined), and all other
            information, content, user interfaces, graphics, registered or
            unregistered trademarks, logos, images, artwork, videos, and
            documents, and the design, structure, selection, coordination,
            expression, “look and feel” and arrangement of such materials, made
            available through the Sites (collectively, the “Content”),
            regardless of its source or creation, is owned, controlled or
            licensed by or to C.COTS App, and is protected by trade dress,
            copyright, patent and trademark laws, and various other intellectual
            property rights and unfair competition laws, and C.COTS App reserves
            and retains all rights in and to such Content. Any reproduction,
            redistribution or other use or exploitation of Software in violation
            of any applicable Software License or in violation of any license
            granted under these Terms or, if applicable, under a Services
            Agreement, is expressly prohibited by law, and may result in civil
            and criminal penalties. “Apache” and “Spark” are trademarks of the
            Apache Software Foundation. Any other third party trademarks,
            service marks, logos, trade names or other proprietary designations,
            that are or may become present within the Sites, including within
            any Content, are the registered or unregistered trademarks of the
            respective parties. Except solely as necessary for you to access the
            Sites for the intended purpose pursuant to these Terms, you may not
            copy, collect, modify, create derivative works or uses of,
            translate, distribute, transmit, publish, re-publish, perform,
            display, post, download, upload, sublicense, transfer, dispose of,
            resell or sell the Content or any other part of the Services. Except
            as expressly set forth in these Terms, these Terms do not grant to
            you any license to any intellectual property rights or other
            proprietary rights, including any implied licenses or licenses
            granted by estoppel or otherwise. Subject to your compliance with
            these Terms, solely for so long as you are permitted by C.COTS App
            to access and use the Sites, and provided that you keep intact all
            copyright and other proprietary notices, you may view Content and
            you may download and print the materials that C.COTS App
            specifically makes available for downloading from the Sites (such as
            white papers or user documentation), in each case solely for
            informational purposes and solely for personal or internal business
            use. 3. INFORMATION SUBMITTED THROUGH OR TO OUR SITES At our sole
            discretion, you may be permitted to provide Submissions (as defined
            in the next sentence) to the Sites (e.g., through our forums).
            “Submissions” are defined to include: any messages, emails, text,
            graphics, code, questions, suggestions, comments, feedback, ideas,
            plans, notes, drawings, sample data, sound, images, video, original
            or creative materials, and other items or materials that you may
            provide to discussion forums, blogs, or other interactive features
            or areas of the Services where you or other users can create, post,
            transmit or store Content. Unless otherwise specifically agreed to
            by you and C.COTS App, by uploading, e-mailing, posting, publishing
            or otherwise transmitting any Submission, you hereby acknowledge
            that such Submission is non-confidential and you automatically grant
            (or warrant that the owner of such rights has expressly granted) to
            C.COTS App a perpetual, irrevocable, worldwide, non-exclusive,
            sub-licensable, fully paid-up and royalty-free license to use, make,
            have made, offer for sale, sell, copy, distribute, perform, display
            (whether publicly or otherwise), modify, adapt, publish, transmit
            and otherwise exploit such Submission, by means of any form, medium,
            or technology now known or later developed, and to grant to others
            rights to do any of the foregoing. In addition, you warrant that all
            so-called moral rights in such Submission have been waived. For each
            Submission, you represent and warrant that you have all rights
            necessary for you to grant the license granted in the prior
            paragraph, and that such Submission, and your provision thereof to
            and through the Sites, does not violate any privacy, publicity,
            contractual, intellectual property, or other right or rights of any
            person or entity or otherwise violate any applicable laws, rules or
            regulations. You acknowledge that C.COTS App may have ideas or
            materials already under consideration or development that are or may
            be similar to your Submissions and that you are not entitled to any
            form of compensation or reimbursement from C.COTS App in connection
            with your Submissions. You agree to be fully responsible for, and to
            pay any and all royalties, fees, damages, and any other monies owing
            any person or entity by reason of, any Submission you provide to the
            Sites. We reserve the right to terminate access to all or any part
            of the Sites for anyone we suspect to be an infringer of our or any
            third party’s intellectual property rights of any kind whatsoever.
            You agree that you will not, and will not allow or authorize any
            third party to, post Submissions containing: o Anything that is or
            may be (a) threatening, harassing, degrading, abusive or hateful;
            (b) an incitement to violence, terrorism or other wrongdoing; (c)
            defamatory or libelous; (d) invasive of privacy rights; (e)
            fraudulent, deceptive, impersonating of any person or entity, or
            misrepresentative of your affiliation with any person or entity; (f)
            obscene, pornographic, indecent, grotesque or otherwise
            objectionable; or (g) protected by copyright, trademark,
            confidentiality obligations, or other proprietary or privacy right
            without the express prior written consent of the owner of such
            right. o Any material, the posting or usage of which would give rise
            to criminal or civil liability, or cause violation of any rules or
            regulations, or that encourages conduct that constitutes a criminal
            offense. o Any virus, worm, Trojan horse or other computer code,
            file, data or program that is harmful, disruptive, corrupted, or
            invasive, or may be or is intended to damage or hijack the operation
            of any hardware or software. o Any information identifiable to a
            particular individual, including but not limited to addresses, phone
            numbers, email addresses, birthdates, Social Security numbers and
            other government-issued identification numbers, payment card and
            other financial account numbers or login credentials, and health
            information. o Any unsolicited or unauthorized advertising,
            promotional materials, junk mail, spam, chain letter, pyramid
            scheme, political campaign message, offering of an investment
            opportunity, or any other form of solicitation. o Any material with
            respect to which you do not have all rights, power and authority
            necessary for its collection, use and processing, or where your use
            and provision to the Sites would breach any agreement between you
            and any third party. C.COTS App generally does not pre-screen or
            monitor Submissions (but reserves the right to do so) and does not
            control Submissions. Therefore, C.COTS App does not guarantee the
            accuracy, quality or appropriateness of Submissions and disclaims
            any responsibility for Submissions, including any liability for
            errors or omissions, or for any loss or damage of any kind incurred
            as a result of their use. However, C.COTS App reserves the right at
            its sole discretion to refuse, delete, screen or edit Submissions,
            provided that even if we do remove or alter any Submission, we shall
            have no obligation to stop our other uses of such Submission or any
            other Submission as permitted above. We have no obligation to store
            any of your Submissions. We have no responsibility or liability for
            the deletion or failure to store, transmit or receive your
            Submissions, nor do we have any responsibility for the security,
            privacy, storage or transmission of other communications originating
            with or involving your use of the Sites, except as may be expressly
            stated in these Terms or in the Privacy Policy. You are solely
            responsible for creating backup copies of and replacing any
            Submissions at your sole cost and expense. Our Privacy Policy
            governs your Submissions. By accepting these Terms, you agree to our
            collection, use, and disclosure of your information as described in
            the Privacy Policy. No one under age 18 may register for an Account
            or provide any personal information to C.COTS App or to the Sites.
            If we learn that we have collected personal information from or
            about anyone under age 18, we will delete that information as
            quickly as possible. If you believe that we might have any
            information from or about a child under 18, please contact us at
            privacy@ccots.app with the subject “Child Data“. C.COTS App reserves
            the right to disclose any Submissions, and the circumstances
            surrounding their transmission, to any third party to operate the
            Sites, to protect C.COTS App or its suppliers or representatives, to
            protect users of the Sites, to comply with legal or regulatory
            obligations, to enforce these Terms, or for any other reason. C.COTS
            App is not responsible or liable for the conduct of, or your
            interactions with, any other users of the Sites (whether online or
            offline), or for any associated loss, damage, injury or harm. By
            using the Site, you may be exposed to Submissions that are
            offensive, indecent or objectionable and you agree that C.COTS App
            bears no liability for such exposure. 4. REQUIRED CONDUCT WHILE
            USING OUR SITES While using the Sites you will comply with all
            applicable laws, rules and regulations. In addition, C.COTS App
            expects users of the Sites to respect the rights and dignity of
            others. Your use of the Sites is conditioned on your compliance with
            the rules of conduct set forth in this Section; any failure to
            comply may also result in termination of your access to the Sites
            pursuant to Section 9 (Suspension or Termination of Access to Our
            Sites). In using the Sites, you agree that you will not, and will
            not allow or authorize any third party to: o Use the Sites or any
            Content for any purpose that is illegal, fraudulent, deceptive or
            unauthorized by these Terms, or would give rise to civil liability,
            or to solicit the performance of any illegal activity or other
            activity which infringes the rights of C.COTS App or others, or to
            encourage or promote any such activity. o Engage in or promote any
            conduct that is offensive, harassing, predatory, stalking, violent,
            threatening, discriminatory, racist, hateful, or otherwise harmful,
            against any individual or group. o Harvest or collect information
            about any third parties, including their email addresses or other
            personally identifiable information. o Send, by email or other
            means, any unsolicited or unauthorized advertising, promotional
            materials, junk mail, spam, chain letter, pyramid scheme, political
            campaign message, offering of an investment opportunity, or any
            other form of solicitation, or conceal or forge headers of emails or
            other messages, or otherwise misrepresent the identity of senders,
            for the purpose of sending spam or other unsolicited messages. o
            Impersonate or post on behalf of, or express or imply the
            endorsement of, any individual or entity, including C.COTS App or
            any of its representatives, or otherwise misrepresent your
            affiliation with a person or entity. o Use the Sites in any manner,
            whether deliberate or otherwise, including without limitation a
            denial of service attack, that could in any way (a) interfere with,
            damage, disable, overburden or impair the functioning of the Sites,
            or C.COTS App’ systems or networks, or any systems or networks
            connected to the Sites, or (b) violate any requirements, procedures,
            policies or regulations of such systems or networks. o Operate
            non-permissioned network services, including open proxies, mail
            relays or recursive domain name servers, or use any means to bypass
            user limitations relating to the Sites. o Use any robot, spider,
            crawler, scraper, deep-link, page-scrape, site search/retrieval
            application or other manual or automated device, program, algorithm
            or methodology or interface not provided by us to access, acquire,
            copy, retrieve, index, scrape, data mine, in any way reproduce or
            circumvent the navigational structure or presentation of the Sites
            or monitor any portion of the Sites or to extract data, or to sell,
            resell, frame, mirror or otherwise exploit for any commercial
            purpose, any portion of, use of, or access to the Sites (including
            any Content, Software and other materials available through the
            Sites), or attempt to circumvent any content filtering techniques we
            may employ. o Remove any copyright, trademark or other proprietary
            rights notice from the Sites or from Content or other materials
            contained on or originating from the Sites. o Create a database of
            any type by systematically downloading and storing any Content
            unless expressly permitted by C.COTS App to do so. o Attempt to gain
            unauthorized access to any portion or feature of the Sites, or any
            other systems or networks connected to the Sites or to any C.COTS
            App server, or to any of the services offered on or through the
            Sites, by hacking, password mining or any other illegitimate means.
            o Use or attempt to use any account you are not authorized to use. o
            Probe, scan, monitor or test the vulnerability of the Sites or any
            network connected to the Sites, or breach the security or
            authentication measures on the Sites or any network connected to the
            Sites. o Modify, adapt, create derivative works of, translate,
            reverse engineer, decompile or disassemble any portion of the Sites
            (including any Content or other materials available through the
            Sites), or do anything that might discover source code or bypass or
            circumvent measures employed to prevent or limit access to any area,
            Content or code within the Sites except as, and solely to the
            extent, expressly authorized under applicable law overriding any of
            these restrictions. o Develop any third-party applications that
            interact with the Sites or Content without our prior written
            consent. o Use or apply the Sites in any manner directly or
            indirectly competitive with any business of C.COTS App. 5. LINKS We
            may from time-to-time at our discretion host or provide links to
            services, products, web pages, softwares or other content of third
            parties (“Third-Party Content”). The inclusion of any link to, or
            the hosting of, any Third Party Content is provided solely as a
            convenience to our users, including you, and does not imply
            affiliation, endorsement, approval, control or adoption by us of the
            Third-Party Content. We make no claims or representations regarding,
            and accept no responsibility or liability for, Third-Party Content
            including without limitation its quality, accuracy, nature,
            ownership or reliability. Your use of Third-Party Content is at your
            own risk. When you leave the Sites to access Third Party Content via
            a link, you should be aware that our policies, including the Privacy
            Policy, no longer govern. You should review the applicable terms and
            policies, including privacy and data gathering policies, of any
            software to which you navigate from the Sites. 6. DISCLAIMER OF
            WARRANTIES YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITES, INCLUDING
            ANY CONTENT, IS AT YOUR SOLE RISK. ALL OF THE SITES AND CONTENT ARE
            PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS, AND C.COTS
            App MAKES NO RELATED REPRESENTATIONS, AND DISCLAIMS ALL POSSIBLE
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE AND NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE SITES OR
            CONTENT ARE ACCURATE, CONTINUOUSLY AVAILABLE, COMPLETE, RELIABLE,
            SECURE, CURRENT, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. C.COTS App CANNOT AND DOES NOT GUARANTEE THAT ANY
            DEFECTS, ERRORS OR OMISSIONS WILL BE CORRECTED, REGARDLESS OF
            WHETHER C.COTS App IS AWARE OF SUCH DEFECTS, ERRORS OR OMISSIONS. TO
            THE EXTENT APPLICABLE STATE LAW DOES NOT ALLOW THE EXCLUSIONS AND
            DISCLAIMERS OF WARRANTIES AS SET FORTH IN THIS SECTION 6, SOME OR
            ALL OF THE ABOVE EXCLUSIONS AND DISCLAIMERS MAY NOT APPLY TO YOU, IN
            WHICH CASE SUCH EXCLUSIONS AND DISCLAIMERS WILL APPLY TO THE FULLEST
            EXTENT PERMITTED BY APPLICABLE LAW. YOU ACKNOWLEDGE THAT THE
            DISCLAIMERS, LIMITATIONS, AND WAIVERS OF LIABILITY SET FORTH IN THIS
            SECTION 6 SHALL SURVIVE ANY EXPIRATION OR TERMINATION OF THESE TERMS
            OR YOUR USE OF THE SITES. 7. LIMITATION OF LIABILITY YOU ACKNOWLEDGE
            AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE
            RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE SITES AND CONTENT
            REMAINS WITH YOU. IN NO EVENT WILL C.COTS App OR ANY OF ITS
            DIRECTORS, EMPLOYEES, AGENTS OR SUPPLIERS BE LIABLE FOR ANY SPECIAL,
            INDIRECT, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES
            OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF
            BUSINESS, LOSS OF PROFITS, LOSS OF DATA, LOSS OF GOODWILL, SERVICE
            INTERRUPTION, COMPUTER DAMAGE, SYSTEM FAILURE OR THE COST OF
            SUBSTITUTE PRODUCTS OR SERVICES) ARISING OUT OF OR IN CONNECTION
            WITH THE SITES, AND ANY CONTENT, SERVICES OR PRODUCTS INCLUDED ON OR
            OTHERWISE MADE AVAILABLE THROUGH THE SITES, REGARDLESS OF THE FORM
            OF ACTION (WHETHER IN CONTRACT, TORT, STRICT LIABILITY, EQUITY OR
            OTHERWISE) AND EVEN IF WE ARE AWARE OF OR HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL OUR TOTAL CUMULATIVE
            LIABILITY TO YOU ARISING OUT OF OR IN CONNECTION WITH THESE TERMS,
            OR FROM THE USE OF OR INABILITY TO USE THE SITES, INCLUDING ANY
            CONTENT, OR FROM THE USE OF OR EXPOSURE TO ANY SUBMISSIONS, EXCEED
            ONE HUNDRED DOLLARS ($100.00). MULTIPLE CLAIMS WILL NOT EXPAND THIS
            LIMITATION. THE FOREGOING LIMITATIONS AND EXCLUSIONS SHALL NOT APPLY
            WITH RESPECT TO ANY LIABILITY ARISING UNDER FRAUD, FRAUDULENT
            MISREPRESENTATION, GROSS NEGLIGENCE, OR ANY OTHER LIABILITY THAT
            CANNOT BE LIMITED OR EXCLUDED BY LAW. ADDITIONALLY, TO THE EXTENT
            APPLICABLE STATE OR OTHER LAW DOES NOT ALLOW THE EXCLUSIONS AND
            LIMITATIONS OF DAMAGES AS SET FORTH IN THIS SECTION 7, SOME OR ALL
            OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU, IN
            WHICH CASE C.COTS App’ LIABILITY TO YOU WILL BE LIMITED BY THIS
            SECTION TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW. THIS
            SECTION WILL BE GIVEN FULL EFFECT EVEN IF ANY REMEDY SPECIFIED IN
            THESE TERMS IS DEEMED TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. THESE
            LIMITATIONS OF LIABILITY FORM AN ESSENTIAL BASIS OF THE BARGAIN
            BETWEEN THE PARTIES. YOU ACKNOWLEDGE THAT THE LIMITATIONS OF
            LIABILITY SET FORTH IN THIS SECTION 7 SHALL SURVIVE ANY TERMINATION
            OR EXPIRATION OF THESE TERMS OR YOUR USE OF THE SITES. 8.
            INDEMNIFICATION To the fullest extent permitted by law, you agree to
            indemnify, defend and hold harmless C.COTS App, its officers,
            directors, shareholders, successors in interest, employees, agents,
            subsidiaries and affiliates, from and against any and all actual or
            threatened third party claims (groundless or otherwise), demands,
            losses, damages, costs and liability, proceedings (at law or in
            equity) and expenses (including reasonable attorneys’ and expert
            fees and costs of investigation) arising out of or in connection
            with (a) your use of the Sites, including without limitation any of
            your Submissions, (b) your breach of these Terms, including your
            breach of any covenant, representation, warranty, term, or condition
            set forth herein, including, without limitation, the obligations set
            forth in Section 3 (Information Submitted Through Our Sites) and
            Section 4 (Required Conduct While Using Our Sites), (c) your
            violation of any law or regulation or of any third party rights,
            including infringement, libel, misappropriation, or other violation
            of any third party’s intellectual property or other legal rights or
            (d) the disclosure, solicitation or use of any personal information
            by you, whether with or without your knowledge or consent. C.COTS
            App reserves the right, however, to assume the exclusive defense and
            control of any matter otherwise subject to indemnification by you
            and, in such case, you agree to cooperate with C.COTS App’ defense
            of such claim, and in no event may you agree to any settlement
            affecting C.COTS App without C.COTS App’ prior written consent. 9.
            SUSPENSION OR TERMINATION OF ACCESS TO OUR SITES Notwithstanding any
            provision to the contrary in these Terms, you agree that C.COTS App
            may, in its sole discretion and with or without prior notice, for
            any or no reason, suspend or terminate your access to any or all of
            the Sites and/or block your future access to any or all of the
            Sites, including without limitation for any of the following
            reasons: (a) if we determine that you have violated any provision,
            or the spirit, of these Terms, (b) in response to a request by a law
            enforcement or other government agency, (c) due to discontinuance or
            material modification of any of the Sites, or (d) due to unexpected
            technical issues or problems. C.COTS App shall not be liable to you
            or any third party for any termination of your access to any part of
            the Sites. The rights and obligations of these Terms which by their
            nature should survive, shall so survive any termination of your use
            of the Sites. 10. CONTACT Questions or comments about the Terms or
            about the Sites may be directed to C.COTS App at the email address
            legal@ccots.app. You may also email us at that address if you would
            like to report what you believe to be a violation of these Terms.
            However, please note that we do not accept any responsibility to
            maintain the confidentiality of any report of a violation you may
            submit to us, including your identity, nor do we commit to providing
            a personal reply to any report you submit, nor are we obligated to
            take action in response to your report. 11. CLAIMS OF COPYRIGHT
            INFRINGEMENT C.COTS App respects the intellectual property rights of
            others and we request that the people who use the Sites do the same.
            The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
            recourse for copyright owners who believe that material appearing on
            the Internet infringes their rights under U.S. copyright law. If you
            believe in good faith that materials available on the Sites infringe
            your copyright, you (or your agent) may send C.COTS App a notice
            requesting that we remove the material or block access to it. If you
            believe in good faith that someone has wrongly filed a notice of
            copyright infringement against you, you may send a counter-notice to
            C.COTS App under applicable provisions of the DMCA. Please note that
            substantial penalties under U.S. copyright law may be levied against
            any filer of a false counter-notice. Notices and counter-notices
            must meet the then-current statutory requirements imposed by the
            DMCA. See 17 U.S.C. § 512(c)(3), available at
            http://www.copyright.gov/title17/92chap5.html for details. Notices
            and counter-notices should be sent to: Attn: Legal Department/DMCA
            Copyright Agent C.COTS App, LLC. legal@ccots.app You should note
            that if you knowingly misrepresent in your notification that the
            material or activity is infringing, you will be liable for any
            damages, including costs and attorneys’ fees, incurred by us or the
            alleged infringer as the result of our relying upon such
            misrepresentation in removing or disabling access to the material or
            activity claimed to be infringing. We encourage you to consult your
            legal advisor before filing a notice or counter-notice. In
            accordance with the DMCA and other applicable law, C.COTS App may at
            our discretion limit access to the Sites and/or terminate the
            accounts of any users who infringe any intellectual property rights
            of others, whether or not there is any repeat infringement. 12.
            GENERAL The Terms and the relationship between each user and C.COTS
            App shall be governed by the laws of the State of California without
            regard to its conflict of law provisions and each party shall submit
            to the personal and exclusive jurisdiction of the courts located in
            San Francisco, California. The application of the United Nations
            Convention on Contracts for the International Sale of Goods is
            expressly excluded. Except to the extent a Services Agreement
            applies, these Terms, along with the Privacy Policy, constitute the
            entire agreement between you and C.COTS App with respect to your use
            of the Sites and supersede all prior or contemporaneous
            communications and proposals, whether electronic, oral or written,
            between you and C.COTS App with respect to the Sites. If any
            provision of the Terms is found by a court of competent jurisdiction
            to be invalid, the parties nevertheless agree that the court should
            endeavor to give effect to the parties’ intentions as reflected in
            the provision, and the other provisions of the Terms remain in full
            force and effect. A party may only waive its rights under these
            Terms by a written document executed by both parties. C.COTS App’
            failure to insist on or enforce strict performance of these Terms
            shall not be construed as a waiver by C.COTS App of any provision or
            any right it has to enforce these Terms, nor shall any course of
            conduct between C.COTS App and you or any other party be deemed to
            modify any provision of these Terms. The headings of the sections of
            these Terms are for convenience of reference only and are not
            intended to restrict, affect or be of any weight in the
            interpretation or construction of the provisions of such sections.
            None of your rights or duties under these Terms may be transferred,
            assigned or delegated by you without our prior written consent, and
            any attempted transfer, assignment or delegation without such
            consent will be void and without effect. We may freely transfer,
            assign or delegate any of our rights or duties under these Terms.
            Subject to the foregoing, these Terms will be binding upon and will
            inure to the benefit of the parties and their respective
            representatives, heirs, administrators, successors and permitted
            assigns. No provision of these Terms is intended for the benefit of
            any third party, and the parties do not intend that any provision
            should be enforceable by a third party. Our relationship is an
            independent contractor relationship, and neither these Terms nor any
            actions by either party may be interpreted as creating an agency or
            partnership relationship. Nothing in these Terms shall be construed
            to obligate C.COTS App to enter into or engage with you on any
            commercial transaction. If you are provided access to any Software,
            you acknowledge that such Software may be subject to regulation by
            local laws and United States government agencies which prohibit
            export or diversion of certain products or information about
            products to certain countries and certain persons. You represent and
            warrant that you will not export or re-export such Software in
            violation of these regulations. You acknowledge that your breach of
            any of the provisions of these Terms may cause immediate and
            irreparable harm to C.COTS App for which we may not have an adequate
            remedy in money damages. We will therefore be entitled to obtain an
            injunction against such breach from any court of competent
            jurisdiction immediately upon request and will be entitled to
            recover from you the costs incurred in seeking such an injunction.
            The availability or exercise of our right to obtain injunctive
            relief will not limit our right to seek or obtain any other remedy.
            You agree that we will not be liable for delays, failures, or
            inadequate performance of the Sites resulting from conditions
            outside of our reasonable control, including but not limited to
            natural disasters or other acts of God, failure of
            telecommunications networks or any other network or utility,
            threatened or actual acts of terrorism or war, riots, labor strikes,
            or governmental acts or orders.
          </p>
          <h4 style={{ textAlign: "initial", fontFamily: "initial" }}>
            Last Updated Jul 19, 2023
          </h4>
        </div>
      </Container>
    </div>
  );
}
