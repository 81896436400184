import React, { Component } from "react";
import { a } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faDatabase,
  faFileCode,
  faFileImport,
  faFilePdf,
  faMicrochip,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";

// Modal Video
//import ModalVideo from "react-modal-video";
//import "../../../node_modules/react-modal-video/scss/modal-video.scss";

// import images
import about from "../images/about2.png";
import landingImg from "../images/undraw_real_time_analytics_re_yliv.svg";
import codeImg from "../images/undraw_firmware_re_fgdy.svg";
import customeImg from "../images/undraw_solution_mindset_re_57bf.svg";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { Icon } from "semantic-ui-react";

export default class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">
                <span className="badge badge-pill badge-soft-primary">
                  Features
                </span>
                <h4 className="title mt-3 mb-4">What we do ?</h4>
                <p className="text-muted para-desc mx-auto mb-0">
                  Our mission is to enable non-developers to create, manipulate
                  and organize unique datasets with AI assisted software.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={6} className="mt-4 pt-2">
              <Card className="border-0 text-center features feature-clean rounded">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-envelope-lock d-block rounded h3 mb-0">
                    <FontAwesomeIcon icon={faFilePdf} />
                    {/* <FontAwesomeIcon  icon={faCoffee} /> */}
                  </i>
                </div>
                <CardBody className="p-0 mt-4">
                  <h5>Effortless Table Extraction from PDF files</h5>
                  <p className="text-muted mb-0">
                    With C.COTS App, extracting tables from PDFs is a breeze.
                    Say goodbye to manual copying and pasting and let C.COTS App
                    handle the heavy lifting.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} md={6} className="mt-4 pt-2">
              <Card className="border-0 text-center features feature-clean rounded">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-edit d-block rounded h3 mb-0">
                    <FontAwesomeIcon icon={faDatabase} />
                  </i>
                </div>

                <CardBody className="p-0 mt-4">
                  <h5>Seamless Data Integration</h5>
                  <p className="text-muted mb-0">
                    C.COTS App goes beyond table extraction. It allows you to
                    seamlessly import data from CSV and Excel files, combining
                    multiple data sources effortlessly.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} md={6} className="mt-4 pt-2">
              <Card className="border-0 text-center features feature-clean rounded">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-circle-layer d-block rounded h3 mb-0">
                    {" "}
                    <FontAwesomeIcon icon={faMicrochip} />
                  </i>
                </div>

                <CardBody className="p-0 mt-4">
                  <h5>Build new scripts with minimal code for automation</h5>
                  <p className="text-muted mb-0">
                    Use C.COTS App to create Python and SQL scripts from scratch
                    and with minimal code for your newly created merged and
                    filtered datasets to help automate the process.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container
          className="mt-100 mt-60"
          style={{ backgroundColor: "#edf0f7", borderRadius: "20px" }}
        >
          <Row className="align-items-center">
            <Col lg={7} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
              <div className="ml-lg-5">
                <div className="section-title mb-3">
                  <span className="badge badge-pill badge-soft-primary">
                    Artificial Intelligence
                  </span>
                  <span className="text-primary h5 mr-2">
                    <i className="uil uil-check-circle align-middle"> </i>{" "}
                  </span>{" "}
                  <h4 className="title my-3">
                    Machine learning models for your dataset{" "}
                  </h4>
                  <p className="text-muted para-desc mb-0">
                    Use C.COTS's simple no-code user interface with Machine
                    Learning to analyze and/or make predictions from your local
                    datasets (i.e. spreadsheets) or simply Machine Learning to
                    build AI capabilities from your very own spreadsheets, CSV,
                    or PDF Tables.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={5} md={6}>
              <img
                src={codeImg}
                className="img-fluid"
                alt=""
                style={{ width: "80%", padding: "15px" }}
              />
            </Col>
          </Row>
        </Container>
        <Container
          className="mt-100 mt-60"
          style={{ backgroundColor: "#edf0f7", borderRadius: "20px" }}
        >
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img
                src={landingImg}
                className="img-fluid"
                alt=""
                style={{ width: "80%", padding: "15px" }}
              />
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
              <div className="ml-lg-5">
                <div className="section-title mb-3">
                  <span className="badge badge-pill badge-soft-primary">
                    Security
                  </span>
                  <span className="text-primary h5 mr-2">
                    <i className="uil uil-check-circle align-middle"> </i>{" "}
                  </span>{" "}
                  <h4 className="title my-3">No storage of sensitive data</h4>
                  <p className="text-muted para-desc mb-0">
                    Never worry about data leaks or cybersecurity threats. To
                    ensure maximum security, C.COTS App does not store any
                    sensitive data of your imported or newly created scripts. We
                    understand your security concerns that's why{" "}
                    <span className="text-primary font-weight-bold">
                      {" "}
                      security is our #1 priority.{" "}
                    </span>{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container
          className="mt-100 mt-60"
          style={{ backgroundColor: "#edf0f7", borderRadius: "20px" }}
        >
          <Row className="align-items-center">
            <Col lg={7} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
              <div className="ml-lg-5">
                <div className="section-title mb-3">
                  <span className="badge badge-pill badge-soft-primary">
                    Customization
                  </span>
                  <span className="text-primary h5 mr-2">
                    <i className="uil uil-check-circle align-middle"> </i>{" "}
                  </span>{" "}
                  <h4 className="title my-3">Continuous Customization </h4>
                  <p className="text-muted para-desc mb-0">
                    We're committed to your success. We understand your needs
                    are unique and are only specific to your organization. Our
                    doors are open to build custom capabilities, features, and
                    APIs specific to your organizational needs.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={5} md={6}>
              <img
                src={customeImg}
                className="img-fluid"
                alt=""
                style={{ width: "80%", padding: "15px" }}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
