import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

let app;
//you check if already initialized
if (firebase.apps.length === 0) {
  app = firebase.initializeApp({
    apiKey: "AIzaSyAWYyMzJfUJCsNS3R-iWuLbJWTJrQvRJzw",
    authDomain: "lyghtai-website.firebaseapp.com",
    projectId: "lyghtai-website",
    storageBucket: "lyghtai-website.appspot.com",
    messagingSenderId: "622077677461",
    appId: "1:622077677461:web:96bf402996e5e79a43309c",
  });
}
const firestore = app.firestore();

export const database = {
  // formatDoc: (doc) => {
  //   return { id: doc.id, ...doc.data() };
  // },

  // usersChatRoom: (user) => {
  //   return { id: user.id, ...user.data() };
  // },
  getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
  downloads: firebase.firestore.FieldValue.increment(1),
  account: firestore.collection("account"),
  user: firestore.collection("user"),
  storageRef: firebase.storage(),
};

export const storage = app.storage();
export const auth = app.auth();

export default app;
