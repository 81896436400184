import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, database } from "../firebase";

const AcctInfoContext = createContext();

export function useAcctInfo() {
  return useContext(AcctInfoContext);
}

export function AcctInfoProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userInfo, setUserInfo] = useState();
  const [errorMsg, setError] = useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (currentUser != null) {
      const docId = currentUser.uid;
      const unsubscribe = database.account
        .doc(docId)
        .onSnapshot((snapshot) => setUserInfo(snapshot.data()));
      console.info(
        "%cUSER_DATA_AVAILABLE",
        "font-size:1rem; color:green;font-weight: bold;"
      );
      return unsubscribe;
    } else if (currentUser == null) {
      console.error("%cUSER_DATA_UNAVAILABLE", "font-size:1rem; ");
    }
  }, [currentUser]);

  console.log("user", currentUser);

  async function signup(email, password) {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .catch((error) => {
        // alert(error.message);
        setError(error.code);
      });
  }

  async function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password).catch((error) => {
      setError(error.code);
    });
  }

  async function resetPassword(email) {
    return auth
      .sendPasswordResetEmail(email)
      .then(console.log("pwd reset"))
      .catch((error) => {
        console.error(error.code);
        setError(error.code);
      });
  }

  function logout() {
    return auth.signOut();

    //.catch((error) => alert(error.message));
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function displayNameGoogleAPI(fullName) {
    return auth.currentUser.updateProfile({ displayName: fullName });
  }

  function updateEmail(email) {
    return auth.currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return auth.currentUser.updatePassword(password);
  }

  const value = {
    currentUser,
    userInfo,
    signup,
    login,
    logout,
    resetPassword,
    errorMsg,
    updateEmail,
    updatePassword,
    resetPassword,
    displayNameGoogleAPI,
  };
  return (
    <AcctInfoContext.Provider value={value}>
      {/* {!loading && children} */}

      {children}
    </AcctInfoContext.Provider>
  );
}

export default AcctInfoProvider;
