// React Basic and Bootstrap
import React, { Component } from "react";
import { Container, Row, Col, Media } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import bloomberg from "../images/Bloomberg-Logo-2004.jpeg";
import deloitte from "../images/deloitte_logo.png";
import DOD from "../images/PictureDOD.png";
import spaceForce from "../images/PictureSPACEFORCE.png";
import airforce from "../images/PictureAF.jpg";
import dfas from "../images/PictureDFAS.png";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
} from "reactstrap";
//Import Components
import SectionTitle from "./SectionTitle";
import SectionTitleClients from "./SectionTitleClients";

class KeyFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          {/* section title */}
          <SectionTitle
            title="Key Features"
            desc="and get access to all key features listed  below. "
          />

          <Row>
            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">
                    Translate dated scripts to Python/SQL{" "}
                  </h4>
                </Media>
              </Media>
            </Col>

            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">Generate Excel Spread Sheets </h4>
                </Media>
              </Media>
            </Col>

            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">Import Data from CSV/Text file</h4>
                </Media>
              </Media>
            </Col>

            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">Code SQL with AI</h4>
                </Media>
              </Media>
            </Col>

            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">No data storage</h4>
                </Media>
              </Media>
            </Col>

            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">Generate CSV files</h4>
                </Media>
              </Media>
            </Col>
            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">
                    Simple UI Design for Machine Learning Models
                  </h4>
                </Media>
              </Media>
            </Col>
            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">Generate Python scripts</h4>
                </Media>
              </Media>
            </Col>

            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">Generate SQL scripts</h4>
                </Media>
              </Media>
            </Col>
            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">
                    Generate code for Machine Learning Models
                  </h4>
                </Media>
              </Media>
            </Col>
            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">
                    Help build AI form your own data{" "}
                  </h4>
                </Media>
              </Media>
            </Col>

            <Col lg={4} md={6} className="mt-4 pt-2">
              <Media className="key-feature align-items-center p-3 rounded shadow">
                <div className="icon text-center rounded-circle mr-3">
                  <FontAwesomeIcon color="#009900" icon={faCheckSquare} />
                </div>
                <Media body>
                  <h4 className="title mb-0">
                    Use C.COTS Machine Learning Models to make predictions
                  </h4>
                </Media>
              </Media>
            </Col>
          </Row>
          <SectionTitleClients
            title="Don't settle for disjointed tools and complicated workflows."
            desc="Streamline your data transformation and machine learning processes with DataMaster Pro. Empower yourself and your team to extract, transform, and create machine learning models with ease. Experience the power of comprehensive data management and analysis today!"
          />
          {/* <CardGroup>
            <Card style={{ marginLeft: "2%" }}>
              <div>
                <CardImg
                  alt="DOD logo"
                  src={DOD}
                  top
                  style={{ padding: "10px", width: "150px" }}
                />
                <CardImg
                  alt="space force"
                  src={spaceForce}
                  top
                  style={{ padding: "10px", width: "150px" }}
                />
                <CardImg
                  alt="air force"
                  src={airforce}
                  top
                  style={{ padding: "10px", width: "150px" }}
                />
                <CardImg
                  alt="DFAS"
                  src={dfas}
                  top
                  style={{ padding: "10px", width: "150px" }}
                />
              </div>
            </Card>
          </CardGroup>
          <SectionTitleClients
            title="Built for enterprises in mind"
            desc="Designed to meet the needs of global organizations for our costumers "
          />
          <CardGroup>
            <Card style={{ marginLeft: "2%" }}>
              <CardImg
                alt="bloomberg image"
                src={bloomberg}
                top
                style={{ padding: "10px" }}
              />
            </Card>
            <Card style={{ marginRight: "2%" }}>
              <CardImg alt="Deloitte image" src={deloitte} top />
            </Card>
          </CardGroup> */}
        </Container>
      </React.Fragment>
    );
  }
}

export default KeyFeature;
